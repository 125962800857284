"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCI = exports.isTest = exports.isDev = exports.isProd = exports.isNode = exports.isWebWorker = exports.isBrowser = void 0;
/* eslint-disable no-restricted-globals */
var isBrowser = typeof window !== "undefined" && typeof window.document !== "undefined";
exports.isBrowser = isBrowser;
var isWebWorker = typeof self === "object" &&
    self.constructor &&
    self.constructor.name === "DedicatedWorkerGlobalScope";
exports.isWebWorker = isWebWorker;
var isNode = typeof process !== "undefined" &&
    process.versions != null &&
    process.versions.node != null;
exports.isNode = isNode;
var isProd = process.env.NODE_ENV === "production" || process.env.NODE_ENV === "PROD" || process.env.ENV === "production" || process.env.ENV === "PROD";
exports.isProd = isProd;
var isDev = process.env.NODE_ENV === "development" || process.env.NODE_ENV === "DEV" || process.env.ENV === "development" || process.env.ENV === "DEV";
exports.isDev = isDev;
var isTest = process.env.NODE_ENV === "test" || process.env.NODE_ENV === "TEST" || process.env.ENV === "test" || process.env.ENV === "TEST";
exports.isTest = isTest;
var isCI = process.env.NODE_ENV === "ci" || process.env.NODE_ENV === "CI" || process.env.ENV === "ci" || process.env.ENV === "CI";
exports.isCI = isCI;
