import axios from 'axios';
import { StorageKeys } from 'helper/dist/constant';

import { Message } from '@arco-design/web-react';

import { storageUtil } from './auth';

// create an axios instance
const service = axios.create({
  baseURL: '/api', // process.env.APP_BASE_API, // url = base url + request url
  timeout: 30000, // request timeout
  withCredentials: true,
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    const token = storageUtil.getToken();
    if (token && !config.headers.Authorization) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
      // eslint-disable-next-line no-param-reassign
      config.headers['X-App-Channel'] = `DIN`;
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response;
    if (response.status >= 500) {
      return Promise.reject(new Error(res.data.message || 'Error'));
    }
    return res;
  },
  (error) => {
    if (error?.response?.status === 401) {
      storageUtil.removeToken();
      Message.error({
        content: 'You need to connect your wallet and mint passport before viewing this page.',
      });
      window.location.href = `${window.REIKI_AIWEB_URL}/home?openLogin=true`;
    }
    return Promise.reject(error);
  },
);

export const fetcher = <T>(url: string): Promise<T> =>
  service.get(url).then((res) => res.data as T);

export default service;
