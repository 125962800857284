import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ErrorBoundary = lazy(() => import('./components/ErrorBoundary'));
const TransferToken = lazy(() => import('./pages/TransferToken'));
const Gifts = lazy(() => import('./pages/Gift'));
const Maintain = lazy(() => import('./pages/Maintain'));

const Minted = lazy(() => import('./pages/Minted'));
const Mint = lazy(() => import('./pages/Mint'));
const GoldLeaf = lazy(() => import('./pages/GoldLeaf'));
const Profile = lazy(() => import('./pages/Profile'));
const Logout = lazy(() => import('./pages/Logout'));
const Home = lazy(() => import('./pages/Home'));
const OauthRedirect = lazy(() => import('./pages/OauthRedirect'));
const Board = lazy(() => import('./pages/Board'));
const LumiBot = lazy(() => import('./pages/LumiBot'));
export const routes: RouteObject[] =
  process.env.MINT_PASSPORT_ONLY !== 'true'
    ? [
        {
          path: '/',
          element: (
            <ErrorBoundary>
              <Home />
            </ErrorBoundary>
          ),
        },
        {
          path: '/TransferToken',
          element: <TransferToken />,
        },
        {
          path: '/minted',
          element: (
            <ErrorBoundary>
              <Minted />
            </ErrorBoundary>
          ),
        },
        {
          path: '/mint',
          element: (
            <ErrorBoundary>
              <Mint />
            </ErrorBoundary>
          ),
        },
        {
          path: '/goldleaf',
          element: (
            <ErrorBoundary>
              <GoldLeaf />
            </ErrorBoundary>
          ),
        },
        {
          path: '/referral',
          element: (
            <ErrorBoundary>
              <GoldLeaf initTab="referral" />
            </ErrorBoundary>
          ),
        },
        {
          path: '/taskboard',
          element: (
            <ErrorBoundary>
              <Board />
            </ErrorBoundary>
          ),
        },
        {
          path: '/leaderboard',
          element: (
            <ErrorBoundary>
              <Board />
            </ErrorBoundary>
          ),
        },
        {
          path: '/exchange',
          element: (
            <ErrorBoundary>
              <Board />
            </ErrorBoundary>
          ),
        },
        {
          path: '/profile',
          element: (
            <ErrorBoundary>
              <Profile />
            </ErrorBoundary>
          ),
        },
        {
          path: '/logout',
          element: (
            <ErrorBoundary>
              <Logout />
            </ErrorBoundary>
          ),
        },
        {
          path: '/lumi',
          element: (
            <ErrorBoundary>
              <LumiBot />
            </ErrorBoundary>
          ),
        },
        {
          path: '/gift',
          element: (
            <ErrorBoundary>
              <Gifts />
            </ErrorBoundary>
          ),
        },
        {
          path: '/oauth-redirect',
          element: (
            <ErrorBoundary>
              <OauthRedirect />
            </ErrorBoundary>
          ),
        },
        {
          path: '/maintain/*',
          element: <Maintain />,
        },
      ]
    : [
        {
          path: '/',
          element: (
            <ErrorBoundary>
              <Home />
            </ErrorBoundary>
          ),
        },
        {
          path: '/minted',
          element: (
            <ErrorBoundary>
              <Minted />
            </ErrorBoundary>
          ),
        },
      ];
