"use strict";
/**
 * the key name of JWT token, currently it as stored by localStorage
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GiftFilter = exports.emailRegexExp = exports.refParam = exports.StorageKeys = void 0;
var StorageKeys;
(function (StorageKeys) {
    StorageKeys["particleToken"] = "particleToken";
    StorageKeys["jwtToken"] = "token";
    StorageKeys["DINToken"] = "DINToken";
    StorageKeys["mintCheck"] = "firstTimeMinted";
    StorageKeys["refCode"] = "refCode";
    StorageKeys["puzzleLastChecked"] = "puzzleLastChecked";
    StorageKeys["puzzleClicked"] = "puzzleClicked";
})(StorageKeys || (exports.StorageKeys = StorageKeys = {}));
exports.refParam = 'ref';
exports.emailRegexExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
var GiftFilter;
(function (GiftFilter) {
    // welcome = 'welcome',
    GiftFilter["new"] = "new";
    GiftFilter["recent"] = "recent";
    GiftFilter["opened"] = "opened";
})(GiftFilter || (exports.GiftFilter = GiftFilter = {}));
