import { StorageKeys } from 'helper/dist/constant';
import { useCallback, useContext, useEffect, useState } from 'react';
import { storageUtil } from 'src/utils/auth';

import { MetamaskContext } from './useMetamask';

export const parseJwt = <T>(token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload) as T;
};
export function checkIsParticle() {
  const token = storageUtil.get(StorageKeys.DINToken);
  if (!token) return false;

  const tokenContent = parseJwt<{
    account: string;
    authFrom: string;
    wallet_source: string | undefined;
    iat: number;
    exp: number;
  }>(token);
  if (tokenContent.wallet_source) return true;

  return false;
}
export const useAuth = () => {
  const [tokenState, setTokenState] = useState<string | null>(storageUtil.getToken());

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  // FIXME remove testAccount
  const testAccount =
    process.env.ENABLE_TEST_ACCOUNT === 'true' ? localStorage.getItem('testAccount') : null;
  // FIXME use new Context
  const { setSignedIn, setAccount } = useContext(MetamaskContext);

  useEffect(() => {
    setTokenState(storageUtil.getToken());
  }, []);

  const initAuth = useCallback(() => {
    setLoading(true);
    let tokenContent: { account: string; iat: number; exp: number } | null = null;
    if (testAccount) {
      setAccount(testAccount);
      setSignedIn(true);
    } else if (tokenState) {
      try {
        tokenContent = parseJwt<{
          account: string;
          iat: number;
          exp: number;
        }>(tokenState);
        if (tokenContent.exp < Date.now() / 1000) {
          console.log('check in useAuth, Token expired');
          storageUtil.removeToken();
          throw new Error('Token expired');
        }
        setAccount(tokenContent.account);
      } catch (e) {
        setSignedIn(false);
        setError(e);
      }
    }
    setLoading(false);
    return testAccount ?? tokenContent?.account ?? null;
  }, [setAccount, setSignedIn, testAccount, tokenState]);

  return { loading, error, initAuth };
};
