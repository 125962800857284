import { createContext, useCallback, useEffect, useState } from 'react';
import { useAuth } from 'src/hooks/useAuth';
import { useMetamask } from 'src/hooks/useMetamask';

export const pageContext = createContext<
  | {
      title: string;
      setTitle: (title: string) => void;
      bannerContent: string;
      setBannerContent: (bannerContent: string) => void;
      showSidebar: boolean;
      setShowSidebar: (showSidebar: boolean) => void;
      showDetailedFooter: boolean;
      setShowDetailedFooter: (showDetailedFooter: boolean) => void;
      showFooter: boolean;
      setShowFooter: (showFooter: boolean) => void;
      showBanner: boolean;
      setShowBanner: (showBanner: boolean) => void;
      backgroundColor: string;
      setBackgroundColor: (backgroundColor: string) => void;
    }
  | Record<string, never>
>({});

export type LayoutProviderProps = {
  children: React.ReactNode;
};

export const LayoutProvider = (props: LayoutProviderProps) => {
  const { children } = props;
  const [title, setTitleState] = useState<string>('');
  const [showBanner, setShowBanner] = useState<boolean>(true);
  const [bannerContent, setBannerContent] = useState<string>(
    '🔥  Join in our Season 1 Referral Program, check out details',
  );
  const [backgroundColor, setBackgroundColor] = useState<string>('#F4F4F4');
  const [showSidebar, setShowSidebar] = useState<boolean>(true);
  const [showDetailedFooter, setShowDetailedFooter] = useState<boolean>(true);
  const [showFooter, setShowFooter] = useState<boolean>(true);

  const setTitle = useCallback(
    (pageTitle: string) => {
      setTitleState(`${pageTitle} | Web3go Dapp`);
    },
    [setTitleState],
  );

  const { initAuth } = useAuth();
  const { initState } = useMetamask();

  useEffect(() => {
    const account = initAuth?.();
    initState?.({
      account,
    });
  }, [initAuth, initState]);

  return (
    <pageContext.Provider
      value={{
        showBanner,
        setShowBanner,
        title,
        setTitle,
        bannerContent,
        setBannerContent,
        showSidebar,
        setShowSidebar,
        showDetailedFooter,
        setShowDetailedFooter,
        showFooter,
        setShowFooter,
        backgroundColor,
        setBackgroundColor,
      }}
    >
      {children}
    </pageContext.Provider>
  );
};
