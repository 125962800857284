import request from 'src/utils/request';

export async function updateProfile(
  key: string,
  {
    arg: user,
  }: {
    arg: {
      email: string | null | undefined;
      name: string | null | undefined;
    };
  },
) {
  if (key && (user.email || user.name)) {
    await request({
      url: `/profile`,
      method: 'patch',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        email: user.email,
        name: user.name,
      }),
    });
    return user.email;
  }
  return null;
}

export async function disconnectSocial(key: string, { arg: socialType }: { arg: string }) {
  if (key && socialType) {
    return request({
      url: `/profile/social/${socialType}`,
      method: 'delete',
    }).then((res) => res.data);
  }
  return socialType;
}

export async function subscribeNewsletter(key: string, { arg: email }: { arg: string }) {
  if (key && email) {
    await request({
      url: `/profile/email/newsletter`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        email,
      }),
    });
    return email;
  }
  return null;
}

export async function uploadAvatar(file: any) {
  const formData = new FormData();
  formData.append('file', file);

  return request({
    url: `/profile/avatar/upload`,
    method: 'post',
    headers: {
      'Content-Type': 'apmultipart/form-data',
    },
    data: formData,
  });
}

export function bind_web3_nonce(data: any): Promise<any> {
  return request({
    url: `/profile/bindwallet/web3_nonce`,
    method: 'post',
    data,
  }).then((res) => res.data);
}
export function bind_web3_challenge(data: any): Promise<any> {
  return request({
    url: `/profile/bindwallet/web3_challenge`,
    method: 'post',
    data,
  }).then((res) => res.data);
}

export function jwtkSign(): Promise<any> {
  return request({
    url: `/jwtk/sign`,
    method: 'post',
  }).then((res) => res.data);
}

export function bind_with_particle(data: any): Promise<any> {
  return request({
    url: `/profile/bindwallet/bind_with_particle`,
    method: 'post',
    data,
  }).then((res) => res.data);
}

export async function updateSponsor() {
  return request({
    url: `/profile/sponsor`,
    method: 'post',
  });
}
