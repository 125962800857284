"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTaskNameBy = void 0;
function getTaskNameBy(type, metaData, description) {
    var _a;
    if (metaData === null || metaData === void 0 ? void 0 : metaData.taskName) {
        return metaData.taskName;
    }
    // return match(type)
    // .with('task', () => task?.name ?? '-')
    if (type === 'gift')
        return 'Welcome gift';
    if (type === 'referral' || type === 'referralL2')
        return 'Referral program';
    if (type === 'checkIn')
        return 'Check in';
    if (type === 'profile')
        return 'Complete Profile';
    if (type === 'quiz' || type === 'puzzle')
        return (_a = metaData === null || metaData === void 0 ? void 0 : metaData.title) !== null && _a !== void 0 ? _a : '-';
    if (type === 'aibot') {
        if (['aibot-chat', 'aibot-share'].indexOf(description) > -1) {
            return 'Hang out with bots';
        }
        console.debug('aibot', metaData, description, 'task name missing');
        return '-';
        //return task?.name ?? '-';
    }
    if (type === 'cost') {
        // eg. cost 6000 gold leaves to upgrade level from L3 to L4
        if (description &&
            (description === null || description === void 0 ? void 0 : description.indexOf('gold leaves to upgrade level from')) > -1) {
            return "Lumi's Talking Power";
        }
        console.debug('cost', metaData, description, 'task name missing');
        return '-';
        // return task?.name ?? '-';
    }
    return '-'; //task?.name ??
}
exports.getTaskNameBy = getTaskNameBy;
