import { envDetect } from 'helper';
import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError() as Error & { statusText: string };

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      {envDetect.isDev ? (
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      ) : (
        <p> Sorry, an unexpected error has occurred.</p>
      )}
    </div>
  );
}
