import { constant } from 'helper';

export const storageUtil = {
  get: (key: constant.StorageKeys) => localStorage.getItem(key),
  set: (key: constant.StorageKeys, value: string) => localStorage.setItem(key, value),
  remove: (key: constant.StorageKeys) => localStorage.removeItem(key),
  removeAll: () => localStorage.clear(),

  getToken: () => {
    const token = localStorage.getItem(constant.StorageKeys.DINToken);
    if (token) return token;
    return '';
  },

  setToken: (token: string) => {
    localStorage.setItem(constant.StorageKeys.DINToken, token);
  },

  removeToken: () => {
    localStorage.removeItem(constant.StorageKeys.DINToken);
  },
};
