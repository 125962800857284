import { AxiosError } from 'axios';
/* eslint-disable no-console */
import {
  BrowserProvider,
  Contract,
  getAddress,
  JsonRpcProvider,
  JsonRpcSigner,
  Network,
} from 'ethers';
import { nftSupport } from 'helper';
import { StorageKeys } from 'helper/dist/constant';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { SiweMessage } from 'siwe';
import { web3_challenge, web3_nonce } from 'src/apis/web3_login';
import { bind_web3_nonce, bind_web3_challenge, bind_with_particle } from 'src/apis/profile';
import { ParticleNetwork } from '@particle-network/auth';
import { ParticleProvider } from '@particle-network/provider';
import { AAWrapProvider, SendTransactionMode, SmartAccount } from '@particle-network/aa';
import { BNBChain, EthereumGoerli } from '@particle-network/chains';
import {
  useAccount,
  useConnectKit,
  useNetwork,
  useParticleConnect,
  useParticleProvider,
} from '@particle-network/connect-react-ui';
import { storageUtil } from 'src/utils/auth';

import { Message, Modal } from '@arco-design/web-react';

import type MetaMaskSDK from '@metamask/sdk';
import { deleteCookie } from './useCookie';
import { checkIsParticle } from './useAuth';

console.info('nft contractAddress:', nftSupport.contractAddress, ',network:', nftSupport.network);

export const MetamaskContext = createContext<
  | {
      account: string;
      setAccount: React.Dispatch<React.SetStateAction<string>>;
      chain: { id: string | null; name: string } | null;
      setChain: React.Dispatch<
        React.SetStateAction<{
          id: string | null;
          name: string;
        } | null>
      >;
      isConnected: boolean | null;
      contract: Contract | null;
      setContract: React.Dispatch<Contract | null>;
      setIsConnected: React.Dispatch<React.SetStateAction<boolean | null>>;
      network: Network | null;
      setNetwork: React.Dispatch<React.SetStateAction<Network | null>>;
      provider: BrowserProvider | JsonRpcProvider | null;
      setProvider: React.Dispatch<React.SetStateAction<BrowserProvider | JsonRpcProvider | null>>;
      signer: JsonRpcSigner | null;
      setSigner: React.Dispatch<React.SetStateAction<JsonRpcSigner | null>>;
      signedIn: boolean;
      setSignedIn: React.Dispatch<React.SetStateAction<boolean>>;
      minted: boolean | null;
      setMinted: React.Dispatch<React.SetStateAction<boolean | null>>;
    }
  | Record<string, never>
>({});

export type MetamaskProviderProps = {
  children: React.ReactNode;
};

export const MetamaskProvider = (props: MetamaskProviderProps) => {
  const { children } = props;
  const [account, setAccount] = useState<string>('');
  const [chain, setChain] = useState<{
    id: string | null;
    name: string;
  } | null>({
    id: null,
    name: '',
  });
  const [contract, setContract] = useState<Contract | null>(null);
  const [isConnected, setIsConnected] = useState<boolean | null>(null);
  const [network, setNetwork] = useState<Network | null>(null);
  const [provider, setProvider] = useState<BrowserProvider | JsonRpcProvider | null>(null);
  const [signer, setSigner] = useState<JsonRpcSigner | null>(null);
  const [signedIn, setSignedIn] = useState<boolean>(!!storageUtil.getToken());
  const [minted, setMinted] = useState<boolean | null>(null);

  return (
    <MetamaskContext.Provider
      value={{
        account,
        setAccount,
        chain,
        setChain,
        isConnected,
        setIsConnected,
        contract,
        setContract,
        network,
        setNetwork,
        provider,
        setProvider,
        signer,
        setSigner,
        signedIn,
        setSignedIn,
        minted,
        setMinted,
      }}
    >
      {children}
    </MetamaskContext.Provider>
  );
};

const chains = (chainId: string) => {
  if (!!Number(chainId) && chainId.length > 9) {
    return 'local';
  }
  switch (chainId) {
    case '1':
      return 'mainnet';
    case '3':
      return 'ropsten';
    case '4':
      return 'rinkeby';
    case '5':
      return 'goerli';
    case '42':
      return 'kovan';
    case '11155111':
      return 'sepiola';
    default:
      return `unknown`;
  }
};

// FIXME useMetamask should be replaced.
export const useMetamask = () => {
  const {
    provider,
    setProvider,
    isConnected,
    setIsConnected,
    chain,
    account,
    network,
    setAccount,
    setChain,
    signer,
    setSigner,
    contract,
    setContract,
    setSignedIn,
    signedIn,
    minted,
    setMinted,
    setNetwork,
  } = useContext(MetamaskContext);
  const connectKit: any = useConnectKit();
  const { connect: pConnect, disconnect: pDisconnect } = useParticleConnect();
  const { chain: pChain, connectId } = useNetwork();
  const pProvider = useParticleProvider();
  const isConnectCalled = useRef(false);
  const pAccount = useAccount();
  const [metamask] = useState<ReturnType<MetaMaskSDK['getProvider']>>(window?.ethereum);

  const disconnect = useCallback(async () => {
    await pDisconnect({
      hideLoading: true,
    });
    storageUtil.removeToken();
    storageUtil.remove(StorageKeys.mintCheck);
    if (process.env.ENABLE_TEST_ACCOUNT === 'true') {
      localStorage.removeItem('testAccount');
      localStorage.removeItem('testMinted');
    }
    deleteCookie(StorageKeys.DINToken);
    setSignedIn(false);
    setIsConnected(false);
    setAccount('');
    window.location.href = `${window.REIKI_AIWEB_URL}/dinLogout`;
  }, [setAccount, setIsConnected, setSignedIn]);

  const handleAccountChange = useCallback(() => {
    console.log('handleAccountChange at ', new Date());
    if (account && account.startsWith('0x')) disconnect();
  }, [disconnect, account]);

  const handleChainChange = useCallback(
    (chainIdHex: unknown) => {
      const chainId = parseInt(chainIdHex as string, 16).toString();
      const chainInfo = { id: chainId, name: chains(chainId) };
      setChain(chainInfo);
    },
    [setChain],
  );

  useEffect(() => {
    if (metamask) {
      metamask.on('accountsChanged', handleAccountChange);
      metamask.on('chainChanged', handleChainChange);
    }

    return () => {
      if (metamask) {
        metamask.removeListener('accountsChanged', handleAccountChange);
        metamask.removeListener('chainChanged', handleChainChange);
      }
    };
  }, [handleAccountChange, handleChainChange, isConnected, metamask]);

  const switchChain = useCallback(
    async (chainHex: string) => {
      if (!metamask) {
        console.warn('metamask is not available.');
        return null;
      }

      await metamask.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainHex }],
      });

      const chainId = parseInt(chainHex, 16).toString();
      const chainInfo = { id: chainId, name: chains(chainId) };
      setChain(chainInfo);
      return chainInfo;
    },
    [metamask, setChain],
  );

  const addChain = useCallback(
    async (chainInfo: { chainId: string; chainName: string; rpcUrls: string[] }) => {
      if (!metamask) {
        console.warn('metamask is not available.');
        return null;
      }

      await metamask.request({
        method: 'wallet_addEthereumChain',
        params: [chainInfo],
      });

      return null;
    },
    [metamask],
  );

  const getContract = useCallback((signerParam?: JsonRpcSigner | null) => {
    if (!signerParam) {
      console.warn('signer is not available.');
      return null;
    }
    return new Contract(nftSupport.contractAddress, nftSupport.nftContractAbi, signerParam);
  }, []);

  const getAccount = useCallback(
    async ({ requestPermission } = { requestPermission: false }) => {
      if (!metamask) {
        console.warn('metamask is not available.');
        return null;
      }
      try {
        const accounts = await metamask.request({
          method: requestPermission ? 'eth_requestAccounts' : 'eth_accounts',
          params: [],
        });
        return getAddress((accounts as string[])?.[0]);
      } catch (e) {
        const error = e as Error;
        if (error.message.includes('Already processing eth_requestAccounts')) {
          Message.error({
            content: 'Please open your MetaMask extension.',
          });
        } else if (error.message.includes('MetaMask is not connected/installed')) {
          Message.error({
            content: 'Please install MetaMask extension.',
          });
        } else if (error.message.includes('invalid address')) {
          console.warn('user not connected to MetaMask.');
        } else {
          Message.error({
            content: 'Something went wrong when request MetaMask',
          });
        }
        console.error(`get account error: ${error.message}`);
      }

      return null;
    },
    [metamask],
  );

  const getChain = useCallback(async () => {
    if (!metamask) {
      console.warn('metamask is not available.');
      return null;
    }

    const result = await metamask.request({
      method: 'eth_chainId',
      params: [],
    });

    const chainId = result as string;
    const chainInfo = { id: chainId, name: chains(chainId) };
    return chainInfo;
  }, [metamask]);

  const particleConnect = useCallback(async () => {
    const innerPProvider = await pConnect({
      id: 'particle',
      preferredAuthType: 'jwt',
      account: storageUtil.get(StorageKeys.particleToken),
      hideLoading: true,
    });
    // const provider = new BrowserProvider(pProvider as any)
    // const signer = await provider.getSigner()
    // const signature = await signer.signMessage('Some data')
    // const contract = getContract(signer)
    // console.log('111', contract)
    let innerAccount: string | null = '';
    let innerContract: Contract | null = null;
    let innerProvider: BrowserProvider | null = null;
    let innerSigner: JsonRpcSigner | null = null;
    try {
      innerProvider = new BrowserProvider(innerPProvider as any);
      innerSigner = await innerProvider.getSigner();
      innerAccount = innerSigner.address;
      innerContract = getContract(innerSigner);
    } catch (e) {
      if ((e as Error).message.includes('unsupported chain')) {
        Message.error({
          content: `Please switch to ${nftSupport.network.chainName} to sign in`,
        });
      }
      console.error(`connect wallet error: ${(e as Error).message}`);
    } finally {
      isConnectCalled.current = false;
    }

    return {
      provider: innerProvider,
      signer: innerSigner,
      contract: innerContract,
      account: innerAccount,
    };
  }, [getContract, pConnect]);
  const connect = useCallback(async () => {
    if (!metamask) {
      console.warn('metamask is not available.');
      Message.error({
        content: (
          <span>
            Looks like you don&apos;t have a crypto wallet. You can go to{' '}
            <a href="https://metamask.io/" target="_blank" rel="noreferrer">
              MetaMask
            </a>{' '}
            to download and deposit some money, or join our{' '}
            <a href="https://discord.gg/web3go" target="_blank" rel="noreferrer">
              Discord
            </a>{' '}
            for assistance!
          </span>
        ),
        duration: 6000,
      });
      return null;
    }
    if (isConnectCalled.current) {
      Message.error({
        content: 'Please open your MetaMask extension.',
      });
      console.warn('connect method already called.');
      return null;
    }
    isConnectCalled.current = true;

    let innerAccount: string | null = '';
    let innerContract: Contract | null = null;
    let innerProvider: BrowserProvider | null = null;
    let innerSigner: JsonRpcSigner | null = null;
    try {
      const chainInfo = await getChain();
      if (chainInfo?.id !== nftSupport.network.chainId) {
        let needAddChain = false;
        try {
          await switchChain(nftSupport.network.chainId);
        } catch (switchError: unknown) {
          const { code } = switchError as { code: number };
          // This error code indicates that the chain has not been added to MetaMask.
          if (code === 4902) {
            needAddChain = true;
          }
        }

        if (needAddChain) {
          try {
            await addChain(nftSupport.network);
            await switchChain(nftSupport.network.chainId);
          } catch (error: unknown) {
            console.error(`add chain error: ${(error as Error).message}`);
          }
        }

        const newChainInfo = await getChain();
        if (newChainInfo?.id !== nftSupport.network.chainId) {
          throw new Error('Cannot use unsupported chain to sign in.');
        }
      }
      innerProvider = new BrowserProvider(metamask);
      innerAccount = await getAccount({ requestPermission: true });
      if (innerAccount) {
        innerSigner = await innerProvider.getSigner();
        innerContract = getContract(innerSigner);
      }
    } catch (e) {
      if ((e as Error).message.includes('unsupported chain')) {
        Message.error({
          content: `Please switch to ${nftSupport.network.chainName} to sign in`,
        });
      }
      console.error(`connect wallet error: ${(e as Error).message}`);
    } finally {
      isConnectCalled.current = false;
    }

    return {
      provider: innerProvider,
      signer: innerSigner,
      contract: innerContract,
      account: innerAccount,
    };
  }, [addChain, getAccount, getChain, getContract, metamask, switchChain]);

  const getNetwork = useCallback(
    async (providerParam: BrowserProvider | JsonRpcProvider | null) => {
      if (!metamask) {
        throw Error('metamask is not available.');
      }

      if (!providerParam) {
        console.warn('provider is not available.');
        return null;
      }

      return (await providerParam.getNetwork()) || 'unknown';
    },
    [metamask],
  );

  const getMintedList = useCallback(async (accountParam?: string | null) => {
    const innerAccount = accountParam;
    if (!innerAccount) {
      console.warn('account is not available.');
      return null;
    }

    // force use of jsonRPC provider since browser provider might have mismatched chain
    const innerProvider = new JsonRpcProvider(nftSupport.network.rpcUrls[0]);
    const innerContract = new Contract(
      nftSupport.contractAddress,
      nftSupport.nftContractAbi,
      innerProvider,
    );
    return innerContract?.tokenOfOwnerTotal(innerAccount);
  }, []);

  const testEnabled = process.env.ENABLE_TEST_ACCOUNT === 'true' && localStorage.getItem('test');
  const checkMinted = useCallback(
    async (accountParam?: string | null) => {
      try {
        const mintedList = await getMintedList(accountParam);
        let result = mintedList && mintedList.length > 0;
        if (testEnabled) {
          console.log('checkMinted getMintedList for accountParam:', accountParam);
          result = true;
        }
        return result;
      } catch (err) {
        console.error(err);
      }
      return false;
    },
    [getMintedList],
  );

  const sponsor = async (targetAccount: string) => {
    const chainId = Number.parseInt(nftSupport.network.chainId, 16);
    const projectId = process.env.PARTICLE_PROJECT_ID || 'bac13541-49d3-4c58-8811-58ee7b2ae5ff';
    const projectKey =
      process.env.PARTICLE_CLIENT_KEY || 'cS4S9ofEYeWxJCLwfwSkUThI5cko8ahM617d29Ml';
    const appId = process.env.PARTICLE_APP_ID || 'a64898f6-e27f-489a-a44e-2866ac61b492';

    if (!chainId || !projectId || !projectKey || !appId) {
      console.error('particle paymaster project config missing, skipping sponsor.');
      return;
    }

    const particle = new ParticleNetwork({
      projectId,
      clientKey: projectKey,
      appId,
      chainName: process.env.IS_TESTNET === 'true' ? EthereumGoerli.name : BNBChain.name,
      chainId: process.env.IS_TESTNET === 'true' ? EthereumGoerli.id : BNBChain.id,
    });

    const particleProvider = new ParticleProvider(particle.auth);

    const smartAccount = new SmartAccount(particleProvider, {
      projectId,
      clientKey: projectKey,
      appId,
      aaOptions: {
        accountContracts: {
          SIMPLE: [
            {
              version: '1.0.0',
              chainIds: [
                chainId,
                process.env.IS_TESTNET === 'true' ? EthereumGoerli.id : BNBChain.id,
              ],
            },
          ],
        },
      },
    });

    smartAccount.setSmartAccountContract({ name: 'SIMPLE', version: '1.0.0' });

    // try gasless transaction first

    const wrapProvider = new AAWrapProvider(smartAccount, SendTransactionMode.Gasless);
    const newProvider = new BrowserProvider(wrapProvider as any);
    const newSigner = await newProvider.getSigner();
    const newContract = getContract(newSigner);
    if (!newContract) {
      console.error('contract is null, skipping sponsor.');
      return;
    }

    await newContract.safeMint(targetAccount);
  };

  const mint = useCallback(
    async (options: { tryGasless?: boolean } = {}) => {
      const { tryGasless = false } = options;
      if (account.includes('@')) {
        Message.error({
          content: `Please go to the 'Account' page to bind your wallet first`,
        });
        return null;
      }
      let innerAccount: string | null = account;
      const isParticle = checkIsParticle();
      if (!isConnected) {
        console.log('not connected ,trying to connect');
        try {
          const result = await (isParticle ? particleConnect() : connect());
          innerAccount = result?.account ?? null;
        } catch (err) {
          console.error(err);
          return null;
        }
      }

      if (!innerAccount) {
        console.warn('account is not available.');
        return null;
      }
      // console.debug('init contract');
      const innerContract: Contract | null = getContract(signer);
      if (!innerContract) {
        console.warn('contract is invalid.');
        return null;
      }

      try {
        if (isParticle && tryGasless) {
          await sponsor(innerAccount);
        } else {
          await (await innerContract.safeMint(innerAccount)).wait();
        }
      } catch (_e) {
        const e = _e as Error;
        if (e && e.message && e.message.indexOf('Drop already claimed or not qualified') > 0) {
          Message.error({
            content: 'Only whitelisted addresses are eligible to access Reiki.',
          });
        } else {
          Message.error({
            content: `Failed to mint passport, reason: ${(e as any)?.info?.error?.message ?? e}`,
          });
        }

        return null;
      }

      // const merkleProofInfo = await requestMerkleTreeProof(Number(nftSupport.network.chainId));
      // const merkleProofInfo = nftSupport.getMerkleProofInfo(innerAccount);
      // if (merkleProofInfo.contractAddress) {
      //   const contractMerkle = new Contract(
      //     merkleProofInfo.contractAddress,
      //     nftSupport.merkleContractAbi,
      //     signerParam,
      //   );

      //   if (!contractMerkle) {
      //     console.warn('contract is not available.');
      //   }

      //   console.info(
      //     merkleProofInfo.contractAddress,
      //     merkleProofInfo.index,
      //     merkleProofInfo.account,
      //     merkleProofInfo.merkleProof,
      //   );
      //   await (
      //     await contractMerkle.claim(
      //       merkleProofInfo.index,
      //       merkleProofInfo.account,
      //       merkleProofInfo.merkleProof,
      //     )
      //   ).wait();
      // }
      function timeout() {
        return new Promise((resolve) => {
          setTimeout(async () => {
            try {
              const result = await checkMinted(innerAccount);
              if (result) {
                setMinted(true);
                resolve(true);
                if (isParticle && tryGasless) {
                  Modal.success({
                    title: `Free Passport`,
                    content: `Congrats! Reiki just handed you a free passport to the world of gold leaves.Time to dive into the gold leaf market and see what treasures await us!`,
                    icon: null,
                    closable: true,
                    okText: 'Explore now!',
                    wrapClassName: 'common-confirm-modal',
                    onOk: () => {
                      window.location.href = `${window.REIKI_AIWEB_URL}/home`;
                    },
                    onCancel: () => {
                      window.location.href = `${window.REIKI_AIWEB_URL}/home`;
                    },
                  });
                } else {
                  window.location.href = `${window.REIKI_AIWEB_URL}/home`;
                }
              }
            } catch (err) {
              resolve(null);
              console.error(err);
            }
          }, 1000);
        });
      }
      const result = await timeout();
      return result;
    },
    [account, isConnected, getContract, signer, connect, checkMinted, setMinted],
  );

  const initState = useCallback(
    async (initValues?: {
      account?: string | null;
      provider?: BrowserProvider | JsonRpcProvider | null;
      signer?: JsonRpcSigner | null;
      contract?: Contract | null;
    }) => {
      const haveToken = !!storageUtil.getToken();
      const accountValue = initValues?.account;
      const metamaskAccountValue = await getAccount();
      if (accountValue && (!accountValue.startsWith('0x') || accountValue.includes('@'))) {
        // async check minted
        // (async () => {
        //   let mintedValue = null;
        //   try {
        //     mintedValue = await checkMinted(accountValue);
        //     setMinted(mintedValue);
        //   } catch (err) {
        //     console.error(err);
        //   }
        // })();
        if (accountValue) {
          setAccount(accountValue);
          setSignedIn(true);
        }
      } else if (accountValue && haveToken) {
        // 如果是particle钱包
        if (checkIsParticle()) {
          try {
            const innerPProvider = await pConnect({
              id: 'particle',
              preferredAuthType: 'jwt',
              account: storageUtil.get(StorageKeys.particleToken),
              hideLoading: true,
            });
            const providerValue = new BrowserProvider(innerPProvider as any);
            const signerValue = await providerValue.getSigner();
            const contractValue = getContract(signerValue);

            // async check minted
            (async () => {
              let mintedValue = null;
              try {
                mintedValue = await checkMinted(accountValue);
                setMinted(mintedValue);
              } catch (err) {
                console.error(err);
              }
            })();

            if (signerValue) setSigner(signerValue);

            if (providerValue) setProvider(providerValue);

            if (contractValue) setContract(contractValue);

            setIsConnected(true);

            if (accountValue) {
              setAccount(accountValue);
              setSignedIn(true);
            }
          } catch (err) {
            console.error(err);
          }
        } else {
          try {
            const providerValue =
              initValues?.provider ??
              (metamask && metamaskAccountValue
                ? new BrowserProvider(metamask)
                : new JsonRpcProvider(nftSupport.network.rpcUrls[0]));
            const signerValue =
              initValues?.signer ?? new JsonRpcSigner(providerValue, accountValue);
            const contractValue = initValues?.contract ?? getContract(signerValue);
            const networkValue = metamaskAccountValue ? await getNetwork(providerValue) : null;
            const chainValue = metamaskAccountValue ? await getChain() : null;
            // async check minted
            (async () => {
              let mintedValue = null;
              try {
                mintedValue = await checkMinted(accountValue);
                setMinted(mintedValue);
              } catch (err) {
                console.error(err);
              }
            })();
            if (signerValue) {
              setSigner(signerValue);
            }
            if (providerValue) {
              setProvider(providerValue);
            }
            if (contractValue) {
              setContract(contractValue);
            }
            if (networkValue) {
              setNetwork(networkValue);
            }
            if (chainValue) {
              setChain(chainValue);
            }
            // if (metamaskAccountValue) {
            //   setIsConnected(true);
            // }
            if (accountValue) {
              setAccount(accountValue);
              setSignedIn(true);
            }
          } catch (err) {
            console.error(err);
          }
        }
      } else {
        setSignedIn(false);
        setIsConnected(false);
      }
    },
    [
      checkMinted,
      getAccount,
      getChain,
      getContract,
      getNetwork,
      metamask,
      setAccount,
      setChain,
      setContract,
      setIsConnected,
      setMinted,
      setNetwork,
      setProvider,
      setSignedIn,
      setSigner,
    ],
  );

  const signIn = useCallback(async () => {
    let innerSigner = signer;
    let innerAccount: string | null = account;
    let innerProvider = provider;
    let innerContract = contract;
    if (!isConnected) {
      const result = await connect();
      innerSigner = result?.signer ?? null;
      innerAccount = result?.account ?? null;
      innerProvider = result?.provider ?? null;
      innerContract = result?.contract ?? null;
    }

    if (!innerSigner) {
      console.warn('signer is not available.');
      return null;
    }

    if (!innerAccount) {
      console.warn('account is not available.');
      return null;
    }

    try {
      const nonce = await web3_nonce({
        address: innerAccount,
      });

      const message = new SiweMessage({
        domain: window.location.host,
        address: innerAccount,
        statement: nonce.challenge,
        uri: window.location.origin,
        version: '1',
        chainId: Number(nftSupport.network.chainId),
      });
      const msg = message.prepareMessage();
      const signature = await innerSigner.signMessage(msg);

      const loginResp = await web3_challenge({
        address: innerAccount,
        nonce: nonce.nonce,
        challenge: JSON.stringify({
          msg,
        }),
        signature,
      });

      storageUtil.setToken(loginResp.extra.token);
      initState({
        account: innerAccount,
        signer: innerSigner,
        provider: innerProvider,
        contract: innerContract,
      });
    } catch (e) {
      let formattedMsg = '';
      if ((e as any).code === 'ACTION_REJECTED') {
        formattedMsg = 'User rejected the request';
      }
      if ((e as AxiosError)?.response?.status === 403) {
        formattedMsg = 'Only whitelisted addresses are eligible to access Reiki';
      }
      console.error(`sign in error: ${(e as any).message}`);
      Message.error({
        content: formattedMsg || 'Something went wrong when sign in',
      });
      throw e;
    }
    return null;
  }, [account, connect, contract, initState, isConnected, provider, signer]);

  const bindParticleWallet = useCallback(
    async (email: string) => {
      let innerSigner = signer;
      let innerAccount: string | null = account;
      let innerProvider = provider;
      let innerContract = contract;
      if (!isConnected) {
        const result = await particleConnect();
        innerSigner = result?.signer ?? null;
        innerAccount = result?.account ?? null;
        innerProvider = result?.provider ?? null;
        innerContract = result?.contract ?? null;
      }

      if (!innerSigner) {
        console.warn('signer is not available.');
        return null;
      }

      if (!innerAccount) {
        console.warn('account is not available.');
        return null;
      }
      bind_with_particle({
        address: innerAccount,
        email,
        jwtk_token: storageUtil.get(StorageKeys.particleToken),
      }).then((d: any) => {
        storageUtil.setToken(d.extra.token);
        storageUtil.set(StorageKeys.particleToken, d.jwtk_token);
        window.location.reload();
      });
      return null;
    },
    [account, contract, isConnected, particleConnect, provider, signer],
  );
  const bindWallet = useCallback(
    async (email: string, isParticle?: boolean) => {
      let innerSigner = signer;
      let innerAccount: string | null = account;
      let innerProvider = provider;
      let innerContract = contract;
      if (!isConnected) {
        const result = await (isParticle ? particleConnect() : connect());
        innerSigner = result?.signer ?? null;
        innerAccount = result?.account ?? null;
        innerProvider = result?.provider ?? null;
        innerContract = result?.contract ?? null;
      }

      if (!innerSigner) {
        console.warn('signer is not available.');
        return null;
      }

      if (!innerAccount) {
        console.warn('account is not available.');
        return null;
      }
      try {
        const nonce = await bind_web3_nonce({
          address: innerAccount,
          email,
        });
        const message = new SiweMessage({
          domain: window.location.host,
          address: innerAccount,
          statement: nonce.challenge,
          uri: window.location.origin,
          version: '1',
          chainId: Number(nftSupport.network.chainId),
        });
        const msg = message.prepareMessage();
        const signature = await innerSigner.signMessage(msg);
        const loginResp = await bind_web3_challenge({
          address: innerAccount,
          nonce: nonce.nonce,
          challenge: JSON.stringify({
            msg,
          }),
          signature,
          email,
        });

        storageUtil.setToken(loginResp.extra.token);
        window.location.reload();
        Message.success({
          content: 'Good news! Next time you can log in via this linked wallet or Google!',
          duration: 6000,
        });
        // initState({
        //   account: innerAccount,
        //   signer: innerSigner,
        //   provider: innerProvider,
        //   contract: innerContract,
        // });
      } catch (e) {
        let formattedMsg = '';
        if ((e as any).code === 'ACTION_REJECTED') {
          formattedMsg = 'User rejected the request';
        }
        if ((e as AxiosError)?.response?.status === 403) {
          formattedMsg = 'Only whitelisted addresses are eligible to access Reiki';
        }
        if ((e as any)?.response?.data?.message) {
          formattedMsg = (e as any)?.response?.data?.message;
        }
        console.error(`sign in error: ${(e as any).message}`);
        Message.error({
          content: formattedMsg || 'Something went wrong when sign in',
          duration: 5000,
        });
        throw e;
      }
      return null;
    },
    [account, connect, contract, isConnected, provider, signer],
  );
  return {
    getContract,
    connect,
    getAccount,
    getNetwork,
    getChain,
    switchChain,
    mint,
    signIn,
    checkMinted,
    setMinted,
    setAccount,
    account,
    chain,
    network,
    provider,
    signer,
    contract,
    isMinted: minted,
    isSignedIn: signedIn,
    isConnected,
    initState,
    disconnect,
    isAvailable: !!metamask,
    bindWallet,
    bindParticleWallet,
  };
};
