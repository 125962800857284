"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numToBigInt = exports.withOrdinalSuffix = exports.ordinalSuffix = exports.convertToRoman = exports.roundToTwo = void 0;
var roundToTwo = function (num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
};
exports.roundToTwo = roundToTwo;
var convertToRoman = function (num) {
    var romanNumList = ["M", "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I"];
    var decimalNumList = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
    var romanized = "";
    for (var i = 0; i < decimalNumList.length; i++) {
        while (decimalNumList[i] <= num) {
            romanized += romanNumList[i];
            num -= decimalNumList[i];
        }
    }
    return romanized;
};
exports.convertToRoman = convertToRoman;
var ordinalSuffix = function (num) {
    var suffixes = ["th", "st", "nd", "rd"];
    var value = num % 100;
    return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
};
exports.ordinalSuffix = ordinalSuffix;
/**
 * make number go from 1 to 1st, 2 to 2nd, 3 to 3rd, etc.
 */
var withOrdinalSuffix = function (num) { return "".concat(num).concat((0, exports.ordinalSuffix)(num)); };
exports.withOrdinalSuffix = withOrdinalSuffix;
/**
 * convert number str to BigInt
 */
var numToBigInt = function (num) {
    if (typeof num != 'string') {
        num = String(num);
    }
    if (!num) {
        return BigInt(0);
    }
    var actualNum = num.startsWith("0x")
        ? Number.parseInt(num, 16)
        : Number.parseInt(num, 10);
    return BigInt(actualNum);
};
exports.numToBigInt = numToBigInt;
