'use client';

import React from 'react';
import { ModalProvider } from '@particle-network/connect-react-ui';
import { WalletEntryPosition } from '@particle-network/auth';
import { BNBChain, EthereumGoerli } from '@particle-network/chains';

import '@particle-network/connect-react-ui/dist/index.css';

function ParticleProvider({ children }: { children: React.ReactNode }) {
  return (
    <ModalProvider
      options={{
        projectId: process.env.PARTICLE_PROJECT_ID || 'bac13541-49d3-4c58-8811-58ee7b2ae5ff',
        clientKey: process.env.PARTICLE_CLIENT_KEY || 'cS4S9ofEYeWxJCLwfwSkUThI5cko8ahM617d29Ml',
        appId: process.env.PARTICLE_APP_ID || 'a64898f6-e27f-489a-a44e-2866ac61b492',
        chains: [process.env.IS_TESTNET === 'true' ? EthereumGoerli : BNBChain],
        particleWalletEntry: {
          // optional: particle wallet config
          displayWalletEntry: true, // display wallet button when connect particle success.
          defaultWalletEntryPosition: WalletEntryPosition.BR,
          supportChains: [process.env.IS_TESTNET === 'true' ? EthereumGoerli : BNBChain],
          customStyle: {
            light: {
              colorAccent: 'rgba(98,191,173,1)',
              colorPrimary: 'rgba(255,255,255,1)',
              colorOnPrimary: 'rgba(98,191,173,1)',
              primaryButtonBackgroundColors: ['rgba(98,191,173,1)', 'rgba(98,191,173,1)'],
              primaryButtonTextColor: 'rgba(33,51,35,1)',
              primaryIconButtonBackgroundColors: ['rgba(98,191,173,0.15)', 'rgba(98,191,173,0.1)'],
              cancelButtonBackgroundColor: '#666666',
              backgroundColors: [
                'rgba(245,245,243,1)',
                [
                  ['rgba(245,239,239,0)', '#ffffff00'],
                  ['#ffffff00', '#ffffff00'],
                ],
              ],
              messageColors: ['rgba(99,67,46,1)', '#ed5d51'],
              borderGlowColors: ['rgba(123,213,249,0)', 'rgba(99,67,46,1)'],
              modalMaskBackgroundColor: '#141430b3',
              primaryIconTextColor: 'rgba(33,51,35,1)',
            },
            dark: {
              colorAccent: 'rgba(98,191,173,1)',
              colorPrimary: 'rgba(50,50,53,1)',
              colorOnPrimary: 'rgba(19,19,30,1)',
              primaryButtonBackgroundColors: ['rgba(75,203,148,0.82)', 'rgba(98,191,173,1)'],
              primaryIconButtonBackgroundColors: ['rgba(99,189,172,1)', 'rgba(98,191,173,1)'],
              primaryButtonTextColor: 'rgba(34,51,51,1)',
              cancelButtonBackgroundColor: '#666666',
              backgroundColors: [
                'rgba(52,52,57,1)',
                [
                  ['rgba(243,239,245,0.99)', '#e6b1f700'],
                  ['#7dd5f94d', '#7dd5f900'],
                ],
              ],
              messageColors: ['rgba(98,191,173,1)', '#ed5d51'],
              borderGlowColors: ['#7bd5f940', '#323233'],
              modalMaskBackgroundColor: '#141430b3',
            },
          }, // optional: custom wallet style
        },
        securityAccount: {
          // optional: particle security account config
          // prompt set payment password. 0: None, 1: Once(default), 2: Always
          promptSettingWhenSign: 1,
          // prompt set master password. 0: None(default), 1: Once, 2: Always
          promptMasterPasswordSettingWhenLogin: 1,
        },
      }}
      theme="auto"
      language="en" // optional：localize, default en
      // walletSort={['Particle Auth', 'Wallet']} // optional：walelt order
      walletSort={['Particle Auth']} // optional：walelt order
      particleAuthSort={[
        // optional：display particle auth items and order
        // 'email',
        // 'phone',
        'google',
        // 'apple',
        // 'facebook',
      ]}
    >
      {/* <ConnectButton /> */}
      {/* <ConnectButton.Custom>
        {({ account, chain, openAccountModal, openConnectModal, openChainModal, accountLoading }) => {
          return (
            <div>
              <button onClick={openConnectModal} disabled={!!account}>
                Open Connect
              </button>
              <br />
              <br />
              <button onClick={openAccountModal} disabled={!account}>
                Open Account
              </button>
              <br />
              <br />
              <button onClick={openChainModal} disabled={!account}>
                Open Switch Network
              </button>
              <div>
                <h3>account</h3>
                <p>{account}</p>
              </div>
            </div>
          )
        }}
      </ConnectButton.Custom> */}
      {children}
    </ModalProvider>
  );
}

export default ParticleProvider;
