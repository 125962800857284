"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEnumValue = exports.shortWalletAddress = exports.uuid = exports.firstLetterUpper = exports.capitalize = exports.snakeToCamel = void 0;
var uuid_1 = require("uuid");
var snakeToCamel = function (str) {
    return str
        .toLowerCase()
        .replace(/[-_][a-z]/g, function (group) { return group.slice(-1).toUpperCase(); });
};
exports.snakeToCamel = snakeToCamel;
var capitalize = function (str) {
    return str.replace(/^[a-z]/g, function (group) { return group.slice(-1).toUpperCase(); });
};
exports.capitalize = capitalize;
var firstLetterUpper = function (str) { return (0, exports.capitalize)(str.toLowerCase()); };
exports.firstLetterUpper = firstLetterUpper;
var uuid = function () { return (0, uuid_1.v4)(); };
exports.uuid = uuid;
var shortWalletAddress = function (address) {
    return address ? "".concat(address.slice(0, 4), "...").concat(address.slice(-4)) : '';
};
exports.shortWalletAddress = shortWalletAddress;
exports.default = { snakeToCamel: exports.snakeToCamel, uuid: exports.uuid, shortWalletAddress: exports.shortWalletAddress };
var isEnumValue = function (something, enumObject) {
    return typeof something === 'string' && Object.values(enumObject).includes(something);
};
exports.isEnumValue = isEnumValue;
