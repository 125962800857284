import { AxiosError } from 'axios';
import { refParam, StorageKeys } from 'helper/dist/constant';
import { Suspense, useEffect } from 'react';
import { SWRConfig } from 'swr';

import { Message } from '@arco-design/web-react';

import ErrorBoundary from './components/ErrorBoundary';
import { LayoutProvider } from './components/Layout/LayoutProvider';
import { setCookie } from './hooks/useCookie';
import { MetamaskProvider } from './hooks/useMetamask';
import ErrorPage from './pages/Error';
import { fetcher } from './utils/request';
import { storageUtil } from './utils/auth';
import ParticleProvider from './components/ParticleProvider';

type AppProps = {
  children: React.ReactNode;
};

const App = (props: AppProps) => {
  const { children } = props;
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(refParam)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      localStorage.setItem(StorageKeys.refCode, urlParams.get(refParam)!);
    }
    if (urlParams.has('test')) {
      localStorage.setItem('test', 'true');
    }
    if (urlParams.has('notest')) {
      localStorage.removeItem('test');
    }
    if (urlParams.has('access_token')) {
      const accessToken = urlParams.get('access_token');
      if (accessToken) {
        storageUtil.setToken(accessToken);
        // navigate('/');
        // eslint-disable-next-line no-restricted-globals
        location.href = location.href.replace(`access_token=${accessToken}`, '');
      }
    }
  }, []);

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <SWRConfig
        value={{
          fetcher,
          onError: (error) => {
            let status = 200;
            if (error instanceof AxiosError) {
              status = error.response?.status ?? 200;
            } else {
              status = error.status ?? 200;
            }

            if (status !== 401 && status !== 403 && status !== 404) {
              Message.error({
                content: error?.message ?? 'Something went wrong',
              });
            }
          },
          revalidateOnFocus: false,
        }}
      >
        <MetamaskProvider>
          <ParticleProvider>
            <LayoutProvider>
              <Suspense fallback={null}>{children}</Suspense>
            </LayoutProvider>
          </ParticleProvider>
        </MetamaskProvider>
      </SWRConfig>
    </ErrorBoundary>
  );
};

export default App;
