import { MerkleTree } from 'helper/src/nft_support';
import request from '../utils/request';

export function web3_nonce(data: {
  address: string;
  // nonce_description: string;
}): Promise<{
  challenge: string;
  nonce: string;
  chain: string;
}> {
  return request({
    url: '/account/web3/web3_nonce',
    method: 'post',
    data,
  }).then((res) => res.data);
}

export function web3_challenge(data: {
  address: string;
  nonce: string;
  challenge: string;
  signature: string;
}): Promise<{
  extra: {
    token: '';
  };
}> {
  return request({
    url: '/account/web3/web3_challenge',
    method: 'post',
    data,
  }).then((res) => res.data);
}

export function requestMerkleTreeProof(chainId: number): Promise<MerkleTree> {
  return request({
    url: `/account/web3/merkleTreeProof/${chainId}`,
    method: 'get',
  }).then((res) => res.data);
}
