import './globalStyles.less';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { ConfigProvider } from '@arco-design/web-react';
import enUS from '@arco-design/web-react/es/locale/en-US';

import App from './App';
import { routes } from './routes';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter(routes);

root.render(
  <ConfigProvider locale={enUS}>
    <App>
      <RouterProvider router={router} />
    </App>
  </ConfigProvider>,
);
